var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tutorial" },
    [
      _c(
        "swiper",
        { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
        [
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page1.png"),
              },
            }),
          ]),
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page2.png"),
              },
            }),
          ]),
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page3.png"),
              },
            }),
          ]),
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page4.png"),
              },
            }),
          ]),
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page5.png"),
              },
            }),
          ]),
          _c("swiper-slide", { staticClass: "swiper-slide" }, [
            _c("img", {
              staticClass: "clickable",
              attrs: {
                src: require("@/assets/img/static/howtouse/tutorial-pc-page6.png"),
              },
              on: { click: _vm.novelCreate },
            }),
          ]),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        1
      ),
      _c("router-link", { attrs: { to: { name: "home" } } }, [
        _c("div", { staticClass: "button-start" }, [
          _vm._v("さっそく執筆を開始する"),
        ]),
      ]),
      _c("router-link", { attrs: { to: { name: "home" } } }, [
        _c("div", { staticClass: "button-close pc" }, [_vm._v("×")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }