var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row terms-box" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("center", [_c("h1", [_vm._v("プライバシーポリシー")])]),
          _c("div", { staticClass: "date" }, [_vm._v("2019年12月25日 第五版")]),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          " 株式会社indent（以下「運営者」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。 "
        ),
      ]),
      _c("h2", [_vm._v("第1条（プライバシー情報）")]),
      _c("p", [
        _vm._v(
          " プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。 "
        ),
      ]),
      _c("h2", [_vm._v("第２条（プライバシー情報の収集方法）")]),
      _c("p", [
        _vm._v(
          "1. 運営者は、ユーザーが本サービスを利用した範囲内において、ユーザーの個人情報を取得します。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 個人情報の取得については、本プライバシーポリシーで公表した適正かつ公正な方法によって行い、未公表の不正な方法により取得することはいたしません。 "
        ),
      ]),
      _c("h2", [_vm._v("第３条（個人情報を収集・利用する目的）")]),
      _c("p", [
        _vm._v("運営者が個人情報を収集・利用する目的は、以下のとおりです。"),
      ]),
      _c("p", [
        _vm._v(
          " （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービスやご購入いただいた月額利用料金、およびそれらの代金などに関する情報を表示する目的 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "（2）ユーザーにお知らせや連絡をするために、連絡先情報を利用する目的"
        ),
      ]),
      _c("p", [
        _vm._v("（3）ユーザーの本人確認を行うために、個人情報を利用する目的"),
      ]),
      _c("p", [
        _vm._v(
          "（4）ユーザーに代金を請求するために、支払に関する情報などを利用する目的"
        ),
      ]),
      _c("p", [
        _vm._v(
          " （5）ユーザーが簡便にデータを入力できるようにするために、運営者に登録されている情報を入力画面に表示させたりする目的 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " （6）代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために利用する目的 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " （7）ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など運営者がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的 "
        ),
      ]),
      _c("p", [_vm._v("（8）上記の利用目的に付随する目的")]),
      _c("h2", [_vm._v("第４条（個人情報の第三者提供）")]),
      _c("p", [
        _vm._v(
          " 運営者は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。 "
        ),
      ]),
      _c("p", [_vm._v("（1）法令に基づく場合")]),
      _c("p", [
        _vm._v(
          "（2）人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき"
        ),
      ]),
      _c("p", [
        _vm._v(
          " （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき "
        ),
      ]),
      _c("p", [
        _vm._v(
          " （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき "
        ),
      ]),
      _c("p", [_vm._v("（5）予め次の事項を告知あるいは公表をしている場合")]),
      _c("p", [_vm._v("◆利用目的に第三者への提供を含むこと")]),
      _c("p", [_vm._v("◆第三者に提供されるデータの項目")]),
      _c("p", [_vm._v("◆第三者への提供の手段または方法")]),
      _c("p", [
        _vm._v("◆本人の求めに応じて個人情報の第三者への提供を停止すること"),
      ]),
      _c("p", [
        _vm._v(
          "前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "（1）運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合"
        ),
      ]),
      _c("p", [
        _vm._v(
          "（2）合併その他の事由による事業の承継に伴って個人情報が提供される場合"
        ),
      ]),
      _c("p", [
        _vm._v(
          " （3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき "
        ),
      ]),
      _c("h2", [_vm._v("第５条（個人情報の開示）")]),
      _c("p", [
        _vm._v(
          " 運営者は、本人から個人情報の開示を求められたときは、本人に対し開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を通知します。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "（1）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合"
        ),
      ]),
      _c("p", [
        _vm._v(
          "（2）運営者の業務の適正な実施に著しい支障を及ぼすおそれがある場合"
        ),
      ]),
      _c("p", [_vm._v("（3）その他法令に違反することとなる場合")]),
      _c("h2", [_vm._v("第6条（個人情報の管理）")]),
      _c("p", [
        _vm._v(
          " 前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。個人情報の管理運営者は、ユーザーの個人情報を適切かつ可能な範囲で安全に管理します。 "
        ),
      ]),
      _c("h2", [_vm._v("第7条（クッキーの使用等について）")]),
      _c("p", [
        _vm._v(
          " 運営者は、ユーザーが運営者サービスを利用したりページを閲覧した際に、ユーザーのクッキー情報等（以下「クッキー等」といいます。）を用いて、ユーザーが閲覧した広告、ページ、アプリ、ユーザーの利用環境などの情報を自動的に取得し、運営者のサーバーに記録いたします。この情報は個人を特定する目的はなく、サービス向上や広告配信の最適化を目的として利用されます。使用目的と使用方法については、以下をご覧ください。 "
        ),
      ]),
      _c("p", [_c("b", [_vm._v("基本設定の保存")])]),
      _c("p", [
        _vm._v(
          " ユーザーにより快適なサービス環境を提供するため、運営者サービスではユーザーがカスタマイズした表示などの設定の保存にクッキーを利用しています。 "
        ),
      ]),
      _c("p", [_c("b", [_vm._v("ログインおよびアカウント認証")])]),
      _c("p", [
        _vm._v(
          " 運営者サービスではアカウントの自動認証にクッキーを利用しています。個人のSNSアカウントまたはNolaの指定するアカウントを使用してログインすることで、ページ移動や2回目以降のログインの際にアカウント情報を入力せずにサービスをご利用いただけます。 "
        ),
      ]),
      _c("p", [_c("b", [_vm._v("利用状況把握、サービス向上検討")])]),
      _c("p", [
        _vm._v(
          " ユーザーの興味やニーズにより適したサービスを提供するための参考として、運営者サービスの利用状況の把握および分析に、Google社の提供するGoogle Analyticsを利用しています。Google Analyticsは、クッキーを利用して運営者サービスの利用状況を分析します。 Google社によるアクセス情報の収集方法および利用方法については、Google Analyticsサービス利用規約およびGoogle社プライバシーポリシーによって定められています。 "
        ),
      ]),
      _c("p", [_vm._v("・Google Analyticsの利用規約")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.com/analytics/terms/jp.html",
              target: "blank",
            },
          },
          [_vm._v("https://www.google.com/analytics/terms/jp.html")]
        ),
      ]),
      _c("p", [_vm._v("・Googleのプライバシーポリシー")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.com/intl/ja/policies/privacy/",
              target: "blank",
            },
          },
          [_vm._v("https://www.google.com/intl/ja/policies/privacy/")]
        ),
      ]),
      _c("p", [
        _vm._v(
          " Google Analyticsの無効化は、以下のページより「Google Analyticsオプトアウトアドオン」をダウンロードおよびインストールし、ブラウザのアドオン設定を変更することで可能です。 "
        ),
      ]),
      _c("p", [_vm._v("・Google Analyticsオプトアウトアドオン")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              href: "https://tools.google.com/dlpage/gaoptout?hl=jal",
              target: "blank",
            },
          },
          [_vm._v("https://tools.google.com/dlpage/gaoptout?hl=ja")]
        ),
      ]),
      _c("h2", [_vm._v("第8条（個人情報の訂正および削除）")]),
      _c("p", [
        _vm._v(
          " ユーザーは、運営者の保有する自己の個人情報が誤った情報である場合には、運営者が定める手続きにより、運営者に対して個人情報の訂正または削除を請求することができます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 運営者は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、当該個人情報の訂正または削除を行い、これをユーザーに通知します。 "
        ),
      ]),
      _c("h2", [_vm._v("第9条（個人情報の利用停止等）")]),
      _c("p", [
        _vm._v(
          " 運営者は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。 "
        ),
      ]),
      _c("h2", [_vm._v("第10条（免責事項）")]),
      _c("p", [
        _vm._v(
          "次に掲げる場合には、第三者による個人情報の取得に関し、運営者は一切の責任を負いません。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "1. ユーザー本人が運営者サービスの機能または別の手段を用いて第三者に個人情報を自ら明らかにした場合"
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. ユーザーが運営者サービス上に入力した情報等により、個人が識別できてしまった場合"
        ),
      ]),
      _c("h2", [_vm._v("第11条（お問い合わせ）")]),
      _c("p", [
        _vm._v(
          " 情報主体であるユーザーご本人からの、お問い合わせ及び苦情相談は、"
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "mailto:nola.contact.team@gmail.com?subject=Nolaに関する問い合わせ&body=下記にお問い合わせ内容をご記入ください。%0d%0a▼内容%0d%0a（お問い合わせ/ご意見・ご要望）%0d%0a▼詳しい内容%0d%0aこちらに内容をご記入ください。",
            },
          },
          [_vm._v("こちら")]
        ),
        _vm._v("で受け付けています。 "),
      ]),
      _c("h2", [_vm._v("第12条（プライバシーポリシーの改定について）")]),
      _c("p", [
        _vm._v(
          "本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 運営者が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }