var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "migrate" }, [
    _c("div", { staticClass: "migrate-form" }, [
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.confirmMode,
              expression: "!confirmMode",
            },
          ],
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("h1", [_vm._v("メールアドレスログインへの移行のお知らせ")]),
          _vm._m(0),
          _c(
            "div",
            [
              _c("text-field", {
                attrs: { type: "text", label: "メールアドレス", name: "email" },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("text-field", {
                attrs: {
                  label: "パスワード",
                  type: "password",
                  name: "password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "attention" }, [
            _vm._v("※ パスワードは7文字以上かつ大文字英字が1つ以上必須です"),
          ]),
          _vm.errorMessage
            ? _c("div", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "button-box" },
            [
              _c(
                "button",
                { staticClass: "button-migrate", attrs: { type: "submit" } },
                [_vm._v("登録する")]
              ),
              _c("router-link", { attrs: { to: { name: "myPage" } } }, [
                _c("div", { staticClass: "button-cancel" }, [
                  _vm._v("キャンセル"),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.confirmMode,
              expression: "confirmMode",
            },
          ],
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitConfirmCode.apply(null, arguments)
            },
          },
        },
        [
          _c("h1", [_vm._v("確認コード入力")]),
          _vm._m(1),
          _c(
            "div",
            [
              _c("text-field", {
                attrs: {
                  label: "確認コード",
                  type: "text",
                  name: "confirmCode",
                  maxlength: 6,
                },
                model: {
                  value: _vm.confirmCode,
                  callback: function ($$v) {
                    _vm.confirmCode = $$v
                  },
                  expression: "confirmCode",
                },
              }),
            ],
            1
          ),
          _vm.errorMessage
            ? _c("div", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
          _c(
            "button",
            { staticClass: "button-migrate", attrs: { type: "submit" } },
            [_vm._v("登録する")]
          ),
        ]
      ),
      _c(
        "button",
        { staticClass: "button-confirm", on: { click: _vm.resend } },
        [_vm._v("＞＞ 確認コードを再送する")]
      ),
      _vm.message
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.message)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " Nolaは、今後多くの方にご利用いただけるよう、しっかりとしたメンテナンスで安定したサービスをお届けするため、Twitterログインからメールアドレスログインへ移行することなりました。"
      ),
      _c("br"),
      _vm._v(" つきましては、「Nola」の"),
      _c("a", { attrs: { href: "/terms", target: "blank" } }, [
        _vm._v("利用規約"),
      ]),
      _vm._v("と"),
      _c("a", { attrs: { href: "/privacy", target: "blank" } }, [
        _vm._v("プライバシーポリシー"),
      ]),
      _vm._v("に同意の上、メールアドレス・パスワードの登録をお願いします。"),
      _c("br"),
      _vm._v(
        " なお、メールアドレスの登録後はログイン時にTwitterログインではなく、"
      ),
      _c("b", [_vm._v("メールアドレスログインのみ")]),
      _vm._v("のご利用となりますことをご了承お願いいたします。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " 入力したメールアドレスに送信されている確認コードを入力してください。"
      ),
      _c("br"),
      _vm._v(
        " メールが見当たらない場合は、念のため「迷惑メールフォルダ」もご確認ください。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }