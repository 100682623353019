var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "error-page" } }, [
    _c("header", { attrs: { id: "header" } }, [
      _c(
        "span",
        { staticClass: "flex-item-logo" },
        [
          _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "logo-img",
              attrs: { src: require("@/assets/img/logo.png") },
            }),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm._m(0),
          _vm._m(1),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("button", [_vm._v("トップページに戻る")]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("h2", [
        _vm._v("最新バージョンのアプリをインストールしてご確認ください"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("div", { staticClass: "button-wrapper" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://apps.apple.com/jp/app/nola-%E5%B0%8F%E8%AA%AC%E3%82%92%E6%9B%B8%E3%81%8F%E4%BA%BA%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E5%9F%B7%E7%AD%86%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%84%E3%83%BC%E3%83%AB/id1468307521",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/app_store_badge.svg"),
                alt: "app store",
              },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "button-wrapper" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://play.google.com/store/apps/details?id=com.nola.app&hl=ja&gl=US",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/static/google_play_badge.png"),
                alt: "google play",
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }