var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("header", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "px-4 pc-block header-content" },
        [
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("p", { staticClass: "self-center" }, [_vm._v("＜ Nolaに戻る")]),
          ]),
          _vm._m(0),
        ],
        1
      ),
      _c("img", {
        staticClass: "subheader sp-block",
        attrs: { src: require("@/assets/img/knowhow/subheader-sp.jpg") },
      }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "subheader pc-block",
        attrs: { src: require("@/assets/img/knowhow/subheader-pc.jpg") },
      }),
      _c("div", { staticClass: "welcome" }, [
        _c("div", { staticClass: "pt-10 text-center" }, [
          _c(
            "svg",
            {
              staticClass: "hat-icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "80",
                height: "80",
                viewBox: "0 0 80 80",
              },
            },
            [
              _c("g", { attrs: { transform: "translate(-920 -413)" } }, [
                _c("rect", {
                  attrs: {
                    width: "80",
                    height: "80",
                    rx: "40",
                    transform: "translate(920 413)",
                    fill: "#bac34d",
                  },
                }),
                _c("g", { attrs: { transform: "translate(939.24 366.969)" } }, [
                  _c("path", {
                    attrs: {
                      d:
                        "M41.021,79.9,22.647,71.444a4.516,4.516,0,0,0-3.773,0L.5,79.9a.86.86,0,0,0,0,1.561l18.374,8.458a4.51,4.51,0,0,0,3.774,0l14.5-6.675v6.721a1.152,1.152,0,0,0-.2,2.251l-.981,6.471h4.515l-.98-6.471a1.152,1.152,0,0,0-.2-2.251V82.251l1.712-.788a.86.86,0,0,0,0-1.561Z",
                      transform: "translate(0 0)",
                      fill: "#fff",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M124.406,293.423a6.241,6.241,0,0,1-2.626-.576l-8.987-4.137v8.242c0,1.809,5.468,4.105,11.613,4.105s11.614-2.3,11.614-4.105V288.71l-8.988,4.137A6.237,6.237,0,0,1,124.406,293.423Z",
                      transform: "translate(-103.646 -200.026)",
                      fill: "#fff",
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c("p", { staticClass: "text-xl" }, [
            _vm._v("創作の学び舎へようこそ。"),
          ]),
          _vm._m(1),
          _c("p", { staticClass: "pt-6 text-right" }, [
            _vm._v("Nola運営スタッフ、フィルムアート社一同"),
          ]),
        ]),
      ]),
      _vm.isNotFound
        ? _c("div", { staticClass: "notfound-view" }, [_vm._m(2)])
        : _c("div", { staticClass: "max-w-lg" }, [
            _c(
              "div",
              { staticClass: "knowhow-grid" },
              [
                _vm._l(_vm.contents, function (content) {
                  return [
                    content.type[0] === "chapter"
                      ? _c(
                          "div",
                          { key: content.title, staticClass: "outer-chapter" },
                          [
                            _c("div", { staticClass: "content-chapter" }, [
                              _c("div", { staticClass: "hat-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "41.521",
                                      height: "30",
                                      viewBox: "0 0 41.521 30",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform: "translate(0 -71.031)",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M41.021,79.9,22.647,71.444a4.516,4.516,0,0,0-3.773,0L.5,79.9a.86.86,0,0,0,0,1.561l18.374,8.458a4.51,4.51,0,0,0,3.774,0l14.5-6.675v6.721a1.152,1.152,0,0,0-.2,2.251l-.981,6.471h4.515l-.98-6.471a1.152,1.152,0,0,0-.2-2.251V82.251l1.712-.788a.86.86,0,0,0,0-1.561Z",
                                            transform: "translate(0 0)",
                                            fill: "#dcb03c",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M124.406,293.423a6.241,6.241,0,0,1-2.626-.576l-8.987-4.137v8.242c0,1.809,5.468,4.105,11.613,4.105s11.614-2.3,11.614-4.105V288.71l-8.988,4.137A6.237,6.237,0,0,1,124.406,293.423Z",
                                            transform:
                                              "translate(-103.646 -200.026)",
                                            fill: "#dcb03c",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("p", [_vm._v(_vm._s(content.title))]),
                            ]),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.toBr(content.subtitle)),
                              },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            key: content.title,
                            staticClass: "knowhow-content",
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(content.id)
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: content.cover.url } }),
                            _c("div", [
                              _c(
                                "p",
                                { staticClass: "knowhow-content-title" },
                                [_vm._v(_vm._s(content.title))]
                              ),
                              content.subTitle
                                ? _c(
                                    "p",
                                    { staticClass: "knowhow-content-subtitle" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(content.subTitle) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", {
                                staticClass: "tag",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.displayedTagHtml(content.tag)
                                  ),
                                },
                              }),
                              _vm._m(3, true),
                            ]),
                          ]
                        ),
                  ]
                }),
              ],
              2
            ),
          ]),
      !_vm.isNotFound
        ? _c("div", [
            _c("div", { staticClass: "pt-16 pb-10 text-center" }, [
              _vm._m(4),
              _c("div", { staticClass: "appeal-button" }, [
                _c("button", { on: { click: _vm.toStore } }, [
                  _c("span", [_vm._v("創作の本屋はこちら")]),
                  _c("img", {
                    staticClass: "appeal-button-logo self-center",
                    attrs: {
                      src: require("@/assets/img/knowhow/filmart-logo.png"),
                    },
                  }),
                ]),
              ]),
            ]),
            _vm.coupon
              ? _c("div", { staticClass: "mt-10 coupon" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "coupon-right" }, [
                    _c("p", { staticClass: "pt-2 text-lg" }, [
                      _vm._v(_vm._s(_vm.couponTitle)),
                    ]),
                    _c("p", { staticClass: "pt-2 text-2xl" }, [
                      _vm._v(_vm._s(_vm.coupon.title)),
                    ]),
                    _c("p", [
                      _vm._v("有効期限：" + _vm._s(_vm.formatExpired) + "まで"),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm._m(6),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "header-content-about",
        attrs: {
          target: "_blank",
          href: "https://note.com/nolanovel/n/n85255a9abf6f",
        },
      },
      [
        _c("img", {
          staticClass: "self-center",
          attrs: { src: require("@/assets/img/knowhow/info-icon.png") },
        }),
        _c("p", { staticClass: "self-center" }, [
          _vm._v("フィルムアート社×Nolaによる創作の学び舎について"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "welcome-body pt-6 text-left" }, [
      _vm._v(" 日頃よりNolaをご利用くださりありがとうございます。"),
      _c("br"),
      _vm._v(
        " この度、多くの創作方法などに関する書籍を出版されているフィルムアート様と共同で、『創作の学び舎』をはじめました。"
      ),
      _c("br"),
      _vm._v(
        " 創作指南書として有名な書籍の一部を出版社様より抜粋してまとめていただいたコンテンツを用意しております。Nola会員であれば誰でも無料で読むことのできるコンテンツとなっておりますので、ぜひ創作のヒントとして新しい学びや発見につなげていただけますと幸いです。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "self-center" }, [
      _c("div", [
        _c("p", [_vm._v("コンテンツの取得に失敗しました。")]),
        _c("p", [_vm._v("時間をおいてご確認ください。")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "read-button" }, [
      _c("div", [_vm._v("無料で読んでみる ＞")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "appeal-text text-center font-semibold" }, [
      _vm._v(" 続きが気になった際には"),
      _c("br"),
      _vm._v("Nolaの会員の方は本を割引価格で購入することができます "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "coupon-left self-center" }, [
      _c("div", [
        _c("p", { staticClass: "coupon-left-title text-xl" }, [
          _vm._v("Nola会員限定クーポン"),
        ]),
        _c("p", { staticClass: "coupon-left-body text-base" }, [
          _vm._v(
            " 創作の本屋では創作に役立つフィルムアート社の書籍を割引価格で購入することができます。"
          ),
          _c("br"),
          _vm._v(
            " Nola無料会員は定価の10%OFF、プレミアム会員は定価の20%OFFで購入できますのでこの機会にぜひお使いください。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("footer", [
      _c("img", {
        staticClass: "cat-walk",
        attrs: { src: require("@/assets/img/knowhow/cat-walk.png") },
      }),
      _c("div", { staticClass: "footer-gray" }),
      _c("div", { staticClass: "footer-main" }, [
        _c("div", { staticClass: "supported" }, [
          _c("span", { staticClass: "self-center" }, [_vm._v("Supported by")]),
          _c("img", {
            attrs: { src: require("@/assets/img/knowhow/filmart-logo.png") },
          }),
        ]),
        _c("p", { staticClass: "text-center" }, [_vm._v("© indent2022")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }