var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "section" },
    [
      _vm.page === "subscription"
        ? _c("img", {
            attrs: { src: require("@/assets/img/premium/subscribed.png") },
          })
        : _vm._e(),
      _vm.page === "unsubscription"
        ? _c("img", {
            attrs: { src: require("@/assets/img/premium/unsubscribed.png") },
          })
        : _vm._e(),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
      _c("div", {
        staticClass: "message",
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
      _c(
        "router-link",
        { staticClass: "button-design", attrs: { to: { name: "home" } } },
        [_vm._v("TOPに移動する")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }