var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      { staticClass: "operation section" },
      [
        _c(
          "span",
          { staticClass: "disclaimer" },
          [
            _c("span", [_vm._v("Nolaの")]),
            _c(
              "router-link",
              {
                staticClass: "membership",
                attrs: { to: { name: "membership" }, target: "_blank" },
              },
              [_vm._v("Nola有料サービス利用規約")]
            ),
            _c("span", [
              _vm._v(
                "にご同意の上、下記のボタンから退会手続きを完了してください。"
              ),
            ]),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "button-design submit margin-bottom",
            attrs: { type: "submit", disabled: _vm.isSubmitting },
            on: { click: _vm.onSubmit },
          },
          [_vm._v(" 退会する ")]
        ),
        _c(
          "router-link",
          {
            staticClass: "button-design cancel",
            attrs: { to: { name: "subscriptionModify" } },
          },
          [_vm._v("キャンセル")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "announcement section" }, [
      _c("p", [_vm._v("プレミアム会員の退会について")]),
      _c("div", { staticClass: "description" }, [
        _c("div", { staticClass: "margin-bottom" }, [
          _vm._v(
            " Nolaのプレミアム会員をご利用くださりありがとうございました。ご利用くださったおかげで、開発費をまかなうことができ、少しずつ機能を改善することができました。本当にありがとうございます。 "
          ),
        ]),
        _c("div", [
          _vm._v(" プレミアム会員の退会に際し、確認事項がございますので "),
          _c("b", [_vm._v("必ずご確認")]),
          _vm._v("の上、ページ下部のボタンより退会をお願いいたします。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "precautions section" }, [
      _c("table", { staticClass: "primary" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "title" }, [_vm._v("退会時のご確認事項")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _c("table", { staticClass: "secondary" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("div", { staticClass: "number" }, [_vm._v("1")]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "sub-title" }, [
                        _vm._v(
                          "有料機能を利用して保存したデータは、編集ができなくなります。"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "message" }, [
                _c("div", [
                  _vm._v(
                    " プレミアム会員を退会すると、登場人物/世界観でカスタマイズで作成した項目データが編集できなくなります。ただし、データ自体は保持されていますので、閲覧は可能となっております。 "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " プロットについては、今後新規作成した場合に序破急モードおよびカスタムモードを利用することができなくなります。 "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("tr", { staticClass: "horizontal-rule" }, [_c("td", [_c("hr")])]),
          _c("tr", [
            _c("td", [
              _c("table", { staticClass: "secondary" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("div", { staticClass: "number" }, [_vm._v("2")]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "sub-title" }, [
                        _vm._v(
                          "月額・年額でお支払いいただいた、残日数分のご利用ができません。"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "message" }, [
                _c("div", [
                  _vm._v(
                    " プレミアム会員を退会すると、即時に退会処理が完了し、プレミアム会員だった場合のご利用残日数が残っていてもご利用いただくことができません。 "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    "そのため、次のお支払いの直前に退会されることを推奨しております。"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }