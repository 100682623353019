var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "announcement section" },
      [
        _c("p", [
          _vm._v(
            "申し訳ございません。アプリ内課金の解約はアプリ版Nolaでご案内させていただいております。"
          ),
        ]),
        _vm._m(0),
        _c(
          "router-link",
          {
            staticClass: "button-design back",
            attrs: { to: { name: "subscriptionModify" } },
          },
          [_vm._v(" 前のページに戻る ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "description" }, [
      _c("div", { staticClass: "margin-bottom" }, [
        _vm._v(
          " ご利用の決済方法がアプリ内課金となっておりますので、アプリ版Nolaの退会ページから解約のお手続きをお願いいたします。お手数をおかけし大変申し訳ございませんが、何卒よろしくお願いいたします。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }