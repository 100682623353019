var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "novellist" }, [
      _vm._m(0),
      _c("hr"),
      _c("div", {
        staticClass: "pagelink",
        attrs: { id: "help_confirmcode_nosend" },
      }),
      _c("hr"),
      _c("div", [
        _c("div", [
          _c("h2", { staticClass: "title" }, [
            _vm._v("◎ 確認コードが届かない場合は？"),
          ]),
          _c("p", [
            _vm._v(
              "登録したメールアドレスに確認コードが届かない場合は、以下の手順をお試しください。"
            ),
          ]),
          _vm._m(1),
          _c(
            "p",
            [
              _vm._v(
                " ①〜③をお試しいただいてもメールが届かない場合は、お手数ですが"
              ),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/contact" } },
                [_vm._v("お問い合わせ")]
              ),
              _vm._v("をお願いします。 "),
            ],
            1
          ),
          _c("img", {
            staticClass: "img-responsive img-help-sp",
            attrs: {
              src: require("@/assets/img/static/help_confirmcode_nosend.png"),
            },
          }),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "pagelink", attrs: { id: "help_data_unsave" } }),
      _c("hr"),
      _c("div", [
        _c("div", [
          _c("h2", { staticClass: "title" }, [
            _vm._v("◎ データの保存ができない場合は？"),
          ]),
          _c("p", [
            _vm._v(
              "データの保存ができない場合は、保存したいデータをバックアップの上、以下の2つの方法をお試しください。"
            ),
          ]),
          _vm._m(2),
          _c(
            "p",
            [
              _vm._v(
                " ①、②をお試しいただいてもデータが保存できない場合は別途詳しく調査を行いますので、お手数ですが"
              ),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/contact" } },
                [_vm._v("お問い合わせ")]
              ),
              _vm._v("をお願いします。 "),
            ],
            1
          ),
          _c("img", {
            staticClass: "img-responsive img-help-sp",
            attrs: { src: require("@/assets/img/static/help_data_unsave.png") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "pagelink", attrs: { id: "help_dontview" } }),
      _c("hr"),
      _c("div", [
        _c("div", [
          _c("h2", { staticClass: "title" }, [
            _vm._v("◎ ページが真っ白で表示されない場合は？"),
          ]),
          _vm._m(3),
          _c(
            "p",
            [
              _vm._v(
                " キャッシュクリアをお試しいただいてもデータが保存できない場合は、お手数ですが"
              ),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/contact" } },
                [_vm._v("お問い合わせ")]
              ),
              _vm._v("をお願いします。 "),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "pagelink", attrs: { id: "delete_book" } }),
      _c("hr"),
      _vm._m(4),
      _c("div", { staticClass: "pagelink", attrs: { id: "delete_account" } }),
      _c("hr"),
      _vm._m(5),
      _c("div", { staticClass: "pagelink", attrs: { id: "data_cashclear" } }),
      _c("hr"),
      _vm._m(6),
      _c("div", { staticClass: "pagelink", attrs: { id: "data_hikitsugi" } }),
      _c("hr"),
      _vm._m(7),
      _c("hr"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "back-page-hedder" }, [
        _c("p", { staticClass: "back-page" }, [
          _c("a", { attrs: { href: "javascript:history.back()" } }, [
            _vm._v("＜ 前のページに戻る"),
          ]),
        ]),
      ]),
      _c("div", [
        _c("h1", { staticClass: "title" }, [_vm._v("ヘルプ")]),
        _c("p", [
          _vm._v(" 「Nola（ノラ）」の機能に関するヘルプを掲載しています。"),
          _c("br"),
          _vm._v(" 不明な点がある場合は、以下の項目からご確認お願いします。 "),
        ]),
        _c("h2", { staticClass: "title-h2" }, [_vm._v("- 目次 -")]),
        _c("ul", { staticClass: "list-group" }, [
          _c("p", [_vm._v("◎ お困りの方へ")]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#help_confirmcode_nosend" } }, [
              _vm._v("確認コードが届かない"),
            ]),
          ]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#help_data_unsave" } }, [
              _vm._v("データが保存できない"),
            ]),
          ]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#help_dontview" } }, [
              _vm._v("ページが真っ白になって表示されない"),
            ]),
          ]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#delete_book" } }, [
              _vm._v("作品を削除したい"),
            ]),
          ]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#delete_account" } }, [
              _vm._v("アカウントを削除したい"),
            ]),
          ]),
          _c("p", [_vm._v("◎ その他のヘルプ")]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#data_cashclear" } }, [
              _vm._v("キャッシュクリアの方法について"),
            ]),
          ]),
          _c("li", { staticClass: "list-group-item" }, [
            _c("a", { attrs: { href: "#data_hikitsugi" } }, [
              _vm._v("Twitterログインアカウントからのデータ引き継ぎについて"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("b", [
        _vm._v("① 迷惑フォルダに確認コードのメールが届いていないか確認する"),
        _c("br"),
        _vm._v(
          " ② 届いていない場合は、ドメインでメールがブロックされている可能性があるので、ご利用中のメールサービスで『@nola-novel.com』のドメインのメール受信を許可する"
        ),
        _c("br"),
        _vm._v(
          " ③ ②を行なった状態で、新規登録画面の下部にある「確認コードを再送する」をクリックしメールが届くか確認する。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("b", [
        _vm._v(
          "① ご利用中のブラウザをキャッシュクリアする（キャッシュクリアの手順は"
        ),
        _c("a", { attrs: { href: "#data_cashclear" } }, [_vm._v("こちら")]),
        _vm._v("）"),
        _c("br"),
        _vm._v(" ② 一度全てのブラウザからログアウトをして、再度ログインをする"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " ページが読み込まれず真っ白になってしまう場合は、真っ白になってしまっているブラウザでキャッシュクリアをお試し頂けますと幸いです。"
      ),
      _c("br"),
      _vm._v(" （キャッシュクリアの手順は"),
      _c("a", { attrs: { href: "#data_cashclear" } }, [_vm._v("こちら")]),
      _vm._v("） "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", [
        _c("h2", { staticClass: "title" }, [_vm._v("◎ 作品の削除について")]),
        _c("p", [
          _vm._v(" 作品の削除は、作品設定画面から行うことができます。"),
          _c("br"),
          _vm._v(
            " 削除したい作品を開き、作品設定画面（スマートフォンの場合は、フッターメニューの「設定」）に移動してください。"
          ),
          _c("br"),
          _vm._v(
            " なお、一度削除した作品は復元することはできませんのでご注意下さい。 "
          ),
        ]),
        _c("img", {
          staticClass: "img-responsive img-help",
          attrs: { src: require("@/assets/img/static/help_delete_book01.png") },
        }),
        _c("img", {
          staticClass: "img-responsive img-help",
          attrs: { src: require("@/assets/img/static/help_delete_book02.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v("◎ アカウントの削除について"),
        ]),
        _c("p", [
          _vm._v(" Nolaアカウントの削除は、マイページから行うことができます。"),
          _c("br"),
          _vm._v(
            " 画面右上からマイページに移動して、ページ下部の案内に従って削除してください。"
          ),
          _c("br"),
          _vm._v(
            " なお、アカウントを削除するとこれまで保存したデータが全て削除され、一度削除したデータは復元することはできませんのでご注意下さい。 "
          ),
        ]),
        _c("img", {
          staticClass: "img-responsive img-help",
          attrs: {
            src: require("@/assets/img/static/help_delete_account01.png"),
          },
        }),
        _c("img", {
          staticClass: "img-responsive img-help",
          attrs: {
            src: require("@/assets/img/static/help_delete_account02.png"),
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v("◎ キャッシュクリアの方法について"),
        ]),
        _c("p", [
          _vm._v(
            " 一部ユーザー様で、メールアドレスログインへのアップデート後にデータ保存できない事象が発生しております。"
          ),
          _c("br"),
          _vm._v(
            " こちらお使いのブラウザのキャッシュが原因と考えられるため、下記の方法にてキャッシュクリアを行っていただき、ご利用いただけますと幸いです。"
          ),
          _c("br"),
          _vm._v(
            " 本件、ご迷惑をおかけし大変申し訳ございませんが、ご対応のほど何卒宜しくお願い致します。 "
          ),
        ]),
        _c("h3", [_vm._v("【PC：Chromeの場合】")]),
        _c("p", [
          _vm._v(
            " Chromeの設定または「︙」をクリックし、[その他のツール] > [閲覧履歴の消去] を選択。"
          ),
          _c("br"),
          _vm._v(
            " ポップアップが表示されるので、Nolaをご利用いただいた任意の [期間] を選択のうえ、[キャッシュされた画像とファイル] をチェックしたのちに[閲覧履歴データを消去する] ボタンを押下してキャッシュクリアしてください。 "
          ),
        ]),
        _c("h3", [_vm._v("【スマホ：Chromeの場合】")]),
        _c("p", [
          _vm._v(
            " 「設定」>「プライバシー」>「閲覧データを消去する」の順に選択し、「キャッシュ」にチェックを入れて「クリア」を押下してキャッシュをクリアしてください。 "
          ),
        ]),
        _c("h3", [_vm._v("【iPhone：Safariの場合】")]),
        _c("p", [
          _vm._v(
            " 「設定」>「Safari」>「詳細」>「Web サイトデータ」の順に選択し、「全 Web サイトデータを削除」を押下してキャッシュをクリアしてください。 "
          ),
        ]),
        _c("h3", [_vm._v("【PC：エッジの場合】")]),
        _c("p", [
          _vm._v(
            " 画面右上にある「・・・」＞設定＞クリアするデータの選択を押下し、「キャッシュされたデータとファイル」にチェックを入れて、「クリア」をクリックしてキャッシュをクリアしてください。 "
          ),
        ]),
        _c("h3", [_vm._v("【PC：Chromeの場合】")]),
        _c("p", [
          _vm._v(
            "「更新」ボタンを右クリック→「キャッシュ消去とハード再読み込み」"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", [
        _c("h2", { staticClass: "title" }, [
          _vm._v("◎ Twitterログインアカウントからのデータ引き継ぎについて"),
        ]),
        _c("p", [
          _vm._v(
            " TwitterログインアカウントのNolaからのデータの引き継ぎは、下記の手順で行うことができます。"
          ),
          _c("br"),
          _vm._v(
            " 先にメールアドレスで「Nola」へ新規登録してしまった場合は、新規登録したアカウントを一度アカウント削除していただき、Twitterログイン後にマイページよりメールアドレスの登録をお願いします。 "
          ),
        ]),
        _c("img", {
          staticClass: "img-responsive img-help-noneborder",
          attrs: { src: require("@/assets/img/static/help-datahikitsugi.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }