var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row terms-box" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("center", [_c("h1", [_vm._v("Nolaスクールアカウント利用規約")])]),
          _c("div", { staticClass: "date" }, [_vm._v("2021年11月1日 第一版")]),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          " このNolaスクールアカウント利用規約（以下「スクールアカウント利用規約」といいます）には、Nolaスクールアカウント（以下「本スクールアカウント」といいます）の利用条件及び株式会社indent（以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本スクールアカウントの利用に際しては、スクールアカウント利用規約の全文をお読みいただいたうえで、スクールアカウント利用規約に同意いただく必要があります。 "
        ),
      ]),
      _c("h2", [_vm._v("第１条　適用関係")]),
      _c("p", [
        _vm._v(
          " 1. スクールアカウント利用規約は、当社が本サイト上で提供する有料のサービスを、ユーザーが利用する際の一切の行為に適用します。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. スクールアカウント利用規約は、本スクールアカウントの利用条件を定めるものです。ユーザーは、Nola利用規約（以下「利用規約」といいます）及びスクールアカウント利用規約に従い本スクールアカウントを利用するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーは、本スクールアカウントを利用することにより、利用規約及びスクールアカウント利用規約の全ての記載内容について同意したものとみなされます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. スクールアカウント利用規約に定めのない事項に関しては、利用規約の定めを適用するものとし、スクールアカウント利用規約の規定と利用規約の規定に矛盾が生じる場合にはスクールアカウント利用規約の規定が優先して適用されるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第2条　定義")]),
      _c("p", [
        _vm._v(
          "スクールアカウント利用規約において使用する用語の意義は、次の各号に定めるとおりとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. 本サイト：当社が運営する「Nola」と称するウェブサイト（PCサイト　https://nola-novel.com/、アプリ (IOS)　 https://apps.apple.com/jp/app/id1468307521、アプリ (Android) https://play.google.com/store/apps/details?id=com.nola.app&hl=ja）といいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本スクールアカウント：ユーザーが提携教育機関に在籍している間に限り、 Nola内の一部の機能も制限なく利用できるアカウントをいいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "3. ユーザー：提携教育機関に在籍している者で、本スクールアカウントを利用する者をいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 提携教育機関： 当社と本スクールアカウントに関して利用提携をしている専門学校、大学、大学院などの教育機関のことをいいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "5. 商品等：本サイト上において販売される商品及びサービスをいいます。"
        ),
      ]),
      _c("h2", [_vm._v("第3条　スクールアカウント利用規約の変更")]),
      _c("p", [
        _vm._v(
          " 当社は、当社の判断により、スクールアカウント利用規約をいつでも任意に変更することができます。変更後のスクールアカウント利用規約は、当社が別途定める場合を除いて、本サイト上に表示した時点より効力を生じるものとします。ユーザーが、スクールアカウント利用規約の変更の効力が生じた後、本スクールアカウントをご利用になる場合は、変更後のスクールアカウント利用規約に同意したものとみなされます。 "
        ),
      ]),
      _c("h2", [_vm._v("第4条　利用資格")]),
      _c("p", [
        _vm._v(
          "本スクールアカウントの利用は、当社が指定する方法によって会員登録をしている方に限るものとします。"
        ),
      ]),
      _c("h2", [_vm._v("第5条　ユーザーの責任")]),
      _c("p", [
        _vm._v(
          " ユーザーは、ユーザー自身の自己責任において本スクールアカウントを利用するものとし、本スクールアカウントを利用してなされた一切の行為及びその結果について一切の責任を負います。 "
        ),
      ]),
      _c("h2", [_vm._v("第6条　サービスの変更等")]),
      _c("p", [
        _vm._v(
          " 当社は、当社の都合により、本スクールアカウントをいつでも任意に追加、変更、中断、終了することができます。 "
        ),
      ]),
      _c("h2", [_vm._v("第7条　ユーザーによるアカウントの削除")]),
      _c("p", [
        _vm._v(
          " ユーザーが本スクールアカウントを削除する場合は、在籍している提携教育機関から案内のある当社所定の方法で行うものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第8条　利用停止")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーが提携教育機関に在籍しなくなった場合には、当社は、当該ユーザーに通知することなく提供中の本スクールアカウントの利用を停止し、当該アカウントをNola無料アカウントに変更することができるものとします。ユーザーはNola無料アカウントにて、引き続きサービスを利用することができます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. ユーザーが、利用規約又はスクールアカウント利用規約に反する行為をしたと当社が認めた場合には、当社は、当該ユーザーに通知することなく、提供中のスクールアカウントの利用を停止することができるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第9条　責任の制限")]),
      _c("p", [
        _vm._v(
          " 次の各号に掲げる事象が発生した場合において、ユーザーがスクールアカウントを利用できない状態が続いたことにより生じた損害等については、当社は賠償責任を負わないものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. 定期的又は緊急に本スクールアカウントを提供するためのネッワーク、サーバー、システムの保守点検や更新等を行う場合 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本スクールアカウントを提供するためのシステムの故障、通信回線の中断等、運用上又は技術上やむを得ない事象が発生した場合 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. 火災、停電、地震、洪水、津波、天災地変等の不可抗力により本スクールアカウントの提供ができなくなった場合 "
        ),
      ]),
      _c("p", [_vm._v("4. その他当社が停止又は中断を必要と判断した場合")]),
      _c("h2", [_vm._v("第10条　免責")]),
      _c("p", [
        _vm._v(
          " 当社は、本スクールアカウントの内容の追加、変更、又は本スクールアカウントの中断、終了によって生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や障害等が生じた場合も同様とします。 "
        ),
      ]),
      _c("h2", [_vm._v("第11条　準拠法及び管轄裁判所")]),
      _c("p", [
        _vm._v(
          "1. 利用規約及びスクールアカウント利用規約の準拠法は、日本法とします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. ユーザーと当社の間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }