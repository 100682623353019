var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("h1", [_vm._v("メンテナンス中")]),
        _vm._m(0),
        _c("router-link", { attrs: { to: "/" } }, [
          _c("button", { staticClass: "button-top" }, [
            _vm._v("トップページへ"),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("p", [
        _vm._v(
          "Nolaは、現在メンテナンス中です。申し訳ありませんがしばらくお待ちください。"
        ),
      ]),
      _c("p", { staticClass: "maintenance-date" }, [
        _vm._v("メンテナンス期間：2019年6月15日(土) 0:00 〜 6:00"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }