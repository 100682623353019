var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contact" }, [
    _c("div", { staticClass: "root" }, [
      _c("h1", [_vm._v("お問い合わせ")]),
      _vm._m(0),
      _vm._m(1),
      _c("a", { staticClass: "button email", attrs: { href: _vm.mail } }, [
        _c("img", {
          attrs: { src: require("@/assets/img/static/sns-icon-mail.png") },
        }),
        _c("div", { staticClass: "email-txt" }, [_vm._v("メールで送信する")]),
      ]),
      _c("p", [
        _vm._v(" よくあるお問い合わせは "),
        _c(
          "a",
          { staticClass: "bold", attrs: { href: _vm.help, target: "blank" } },
          [_vm._v("こちら")]
        ),
        _vm._v(" から確認ができます。"),
        _c("br"),
        _vm._v(" お困りの際はご活用いただけますと幸いです。 "),
      ]),
      _c("h2", [_vm._v("◎ 調査用の情報")]),
      _c("textarea", {
        ref: "textarea",
        attrs: { type: "text", readonly: "" },
        domProps: { value: _vm.diagInfo },
      }),
      _c(
        "button",
        { staticClass: "button-copy", on: { click: _vm.copyToClipboard } },
        [_vm._v("クリップボードにコピー")]
      ),
      _vm.message
        ? _c("div", { staticClass: "info" }, [_vm._v(_vm._s(_vm.message))])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" Nolaをご愛用いただきありがとうございます。"),
      _c("br"),
      _vm._v(
        " サービスに対するご意見・ご感想は、メールまたは公式LINEアカウントへご連絡お願いします。"
      ),
      _c("br"),
      _vm._v(" なお、バグに関するお問い合わせの際は"),
      _c("b", [_vm._v("「調査用の情報」")]),
      _vm._v(
        "を添付の上、送付いただくとスムーズに調査が進みますのでご協力をお願いします。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "button line",
        attrs: { href: "https://line.me/R/ti/p/%40kyv0221f", target: "blank" },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/img/static/sns-icon-line.png") },
        }),
        _c("div", { staticClass: "line-txt" }, [_vm._v("公式LINEで送信する")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }