var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row terms-box" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("center", [_c("h1", [_vm._v("共有ポリシー")])]),
          _c("div", { staticClass: "date" }, [_vm._v("2021年9月14日 初版")]),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          " １　Nola共有機能の目的は、第三者からのアドバイスや批評を通して各作品の推敲に寄与することです。そのため本機能では作品や作者に対して自由にコメントすることができますが、コメントは上記目的に沿った適切な表現で行い、誹謗中傷等本機能の目的を阻害するような不適切な表現をしないよう注意してください。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " ２　本機能では、共同執筆やゲームシナリオの制作などチームで制作をされる方向けに、同じ共有リンクのURLを受け取った全ての人がお互いのコメントを閲覧することができるように開発しております。"
        ),
        _c("br"),
        _vm._v(
          " 各個人と個別にコメントをやり取りしたい場合や、コメントを複数人で共有したくない場合は、共有する一人一人に別々の共有リンクを発行して渡すようにしてください。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("３　共有された作品の盗作は厳に慎んでください。")]),
      _c("br"),
      _c("p", [
        _vm._v(
          " ４　共有するために作成されるリンクは、リンクを共有されたNola会員であれば誰でもアクセス可能です。そのため、例えば、作品を共有したNola会員がSNS等でリンクを掲載した場合、共有を意図していないNola会員に作品を閲覧される可能性がありますので、作品を共有する第三者の選択には十分注意してください。（なお、この場合Nola会員でなければ閲覧できませんが、新規で会員登録をすると閲覧可能になりますので、SNS等に共有してしまうと潜在的にはNola会員以外の第三者に閲覧される可能性があります。）"
        ),
        _c("br"),
        _vm._v(
          " なお、万が一、発行した共有リンクを共有を希望する人以外へ送ってしまった（送られてしまった）場合は、「共有リンク一覧」から任意の共有リンクを選択して画面下の【共有リンクを停止する】を押下することで、共有リンクの利用を停止することができますのでご対応をお願いいたします。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " ５　作品を共有されたNola会員は、当該作品の作者の許可なくSNSやメール等の通信手段で拡散することは一切禁止しております。作品をより多くの方に知ってもらいたいという動機であっても拡散する際は作者の許可を必ず取ってください。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          " ６　本機能の利用に関して、上記の注意事項並びに社会一般的なルールを遵守しなかったことにより、第三者（Nola会員であるか否かは問いません）との間でトラブル・紛争が発生した場合は、全て自己の責任でもって対処してください。当社は当該トラブル・紛争について一切の責任を負いません。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }