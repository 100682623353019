var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "announce-txt" }, [
        _c(
          "h1",
          [
            _vm._v("ご利用の前に、"),
            _c("font", { attrs: { color: "red" } }, [_vm._v("必ず")]),
            _vm._v("ご対応をお願いします。"),
          ],
          1
        ),
        _vm._m(0),
        _c("h3", [_vm._v("▼ ブラウザごとのキャッシュクリアの手順")]),
      ]),
      _vm._m(1),
      _vm._m(2),
      _c(
        "router-link",
        { staticClass: "link", attrs: { to: "/auth/signin" } },
        [_c("button", [_vm._v("上記について確認して\b始める")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " 一部Nolaユーザー様で、メールアドレスログインへのアップデート後にデータ保存できない事象が発生しております。"
      ),
      _c("br"),
      _vm._v(
        " こちらお使いのブラウザのキャッシュが原因と考えられるため、下記の方法にてご利用開始前にキャッシュクリアをお願いいたします。"
      ),
      _c("br"),
      _vm._v(
        " お手数をお願いいたしますが、ご対応のほど何卒宜しくお願い致します。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "accbox" }, [
      _c("label", { attrs: { for: "label1" } }, [_vm._v("PC：Chromeの場合")]),
      _c("input", {
        staticClass: "cssacc",
        attrs: { type: "checkbox", id: "label1" },
      }),
      _c("div", { staticClass: "accshow" }, [
        _c("p", [
          _vm._v(
            " Chromeの設定または「︙」をクリックし、[その他のツール] > [閲覧履歴の消去] を選択。"
          ),
          _c("br"),
          _vm._v(
            " ポップアップが表示されるので、Nolaをご利用いただいた任意の [期間] を選択のうえ、[キャッシュされた画像とファイル] をチェックしたのちに[閲覧履歴データを消去する] ボタンを押下してキャッシュクリアしてください。 "
          ),
        ]),
      ]),
      _c("label", { attrs: { for: "label4" } }, [_vm._v("PC：エッジの場合")]),
      _c("input", {
        staticClass: "cssacc",
        attrs: { type: "checkbox", id: "label4" },
      }),
      _c("div", { staticClass: "accshow" }, [
        _c("p", [
          _vm._v(
            " 画面右上にある「・・・」＞設定＞クリアするデータの選択を押下し、「キャッシュされたデータとファイル」にチェックを入れて、「クリア」をクリックしてキャッシュをクリアしてください。 "
          ),
        ]),
      ]),
      _c("label", { attrs: { for: "label2" } }, [
        _vm._v("スマホ：Chromeの場合"),
      ]),
      _c("input", {
        staticClass: "cssacc",
        attrs: { type: "checkbox", id: "label2" },
      }),
      _c("div", { staticClass: "accshow" }, [
        _c("p", [
          _vm._v(
            " 「設定」>「プライバシー」>「閲覧データを消去する」の順に選択し、「キャッシュ」にチェックを入れて「クリア」を押下してキャッシュをクリアしてください。 "
          ),
        ]),
      ]),
      _c("label", { attrs: { for: "label3" } }, [
        _vm._v("iPhone：Safariの場合"),
      ]),
      _c("input", {
        staticClass: "cssacc",
        attrs: { type: "checkbox", id: "label3" },
      }),
      _c("div", { staticClass: "accshow" }, [
        _c("p", [
          _vm._v(
            " 「設定」>「Safari」>「詳細」>「Web サイトデータ」の順に選択し、「全 Web サイトデータを削除」を押下してキャッシュをクリアしてください。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "announce-txt" }, [
      _c("h3", [_vm._v("複数の端末でNolaをご利用中の方へ")]),
      _c("p", [
        _vm._v(
          " 最後に、複数端末でご利用の場合は、一度全てログアウトしたのちに、メールアドレスログインを行ってからご利用をお願いいたします。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }