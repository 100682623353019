var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row terms-box" }, [
      _c(
        "div",
        { staticClass: "col-lg-12" },
        [
          _c("center", [_c("h1", [_vm._v("Nola利用規約")])]),
          _c("div", { staticClass: "date" }, [_vm._v("2020年12月28日 第十版")]),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", [_vm._v("第1条（はじめに）")]),
      _c("p", [
        _vm._v(
          " 「Nola」と称するサービス（https://nola-novel.com/、https://apps.apple.com/jp/app/id1468307521、https://play.google.com/store/apps/details?id=com.nola.app&hl=ja&gl=US、以下「本サイト」といいます。）は株式会社indent（以下「運営者」といいます。）が運営しております。本規約は、本サイトを利用、または閲覧される利用者（以下「ユーザー」といいます。）全てに適応されます。なお、本規約につきましては予告なく変更することがあります。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "ユーザーは、本規約に同意の上、本規約に従い本サイトを利用するものとします。"
        ),
      ]),
      _c("h2", [_vm._v("第2条（本規約での用語定義）")]),
      _c("p", [
        _vm._v("本規約において使用する用語は、次に定めるとおりとします。"),
      ]),
      _c("p", [
        _vm._v(
          " 1. 「本サイト」とは、運営者が運営する「Nola」と称するウェブサイト（"
        ),
        _c(
          "a",
          { attrs: { href: "https://nola-novel.com/", target: "blank" } },
          [_vm._v("https://nola-novel.com/")]
        ),
        _vm._v("）およびアプリケーション（"),
        _c(
          "a",
          {
            attrs: {
              href: "https://apps.apple.com/jp/app/id1468307521",
              target: "blank",
            },
          },
          [_vm._v("https://apps.apple.com/jp/app/id1468307521")]
        ),
        _vm._v("、"),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://play.google.com/store/apps/details?id=com.nola.app&hl=ja&gl=US",
              target: "blank",
            },
          },
          [
            _vm._v(
              "https://play.google.com/store/apps/details?id=com.nola.app&hl=ja&gl=US"
            ),
          ]
        ),
        _vm._v("）をいいます。 "),
      ]),
      _c("p", [
        _vm._v(
          "2. 「本サービス」とは、本サイト上で提供される全てのサービスをいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "3. 「ユーザー」とは、本サービスを閲覧、利用している者をいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 「登録SNSアカウント」とは、本サービスの提供を受ける目的で、ユーザーが運営者に提供したSNSアカウントの情報をいいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 5. 「登録メールアドレス」とは、本サービスの提供を受ける目的で、ユーザーが運営者に提供したメールアドレスの情報をいいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 6. 「パスワード」とは、本サービスを利用するに当たって、登録メールアドレスと照合して本人を識別するための文字列をいいます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "7. 「アカウント情報」とは、登録SNSアカウント、登録メールアドレス及びパスワードをいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "8. 「保存データ」とは、画像、テキスト等本サービスを利用して保存できる情報をいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "9. 「データ保存」とは、ユーザーが本サービスに保存データをアップロードする行為をいいます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "10. 「全データ」とは、本サービスに保存されるユーザーのすべてのアカウント情報及び保存データをいいます。"
        ),
      ]),
      _c("h2", [_vm._v("第3条（本サービスのご利用）")]),
      _c("p", [
        _vm._v(
          "1. ユーザーは、本規約に同意のうえ、本規約の定めに従って本サービスを利用しなければなりません。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. ユーザーのご利用端末、通信環境によっては、本サービスがご利用できない場合がございます。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. 本サイトの提供する所定の登録形式のうちTwitterログインで本サービスをご利用の場合、保存データをアカウント間で移動することはできません（例：別の登録SNSアカウントに保存データ等の移動はできません）。 "
        ),
      ]),
      _c("h2", [_vm._v("第4条（本サービスの提供区域）")]),
      _c("p", [
        _vm._v(
          " 本サービスの提供区域は、利用契約等で特に定める場合を除き、日本国内に限定されるものとします。海外からの利用を妨げるものではありませんが、その不具合等について運営者は一切その責任を免れるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第5条（利用料金）")]),
      _c("p", [_vm._v("1. 本サービスの登録は無料とします。")]),
      _c("p", [
        _vm._v(
          " 2. 有料サービスの内容、利用料金、支払方法等については、別途『"
        ),
        _c("a", { attrs: { href: "/membership", target: "blank" } }, [
          _vm._v("Nola有料サービス利用規約"),
        ]),
        _vm._v("』の定めるものとします。 "),
      ]),
      _c("h2", [_vm._v("第6条（本規約の範囲）")]),
      _c("p", [
        _vm._v(
          " 1. 本サイトには、本規約及びその他の利用規約等において、本サービスの利用条件が規定されています。その他の利用条件等は名称の如何に関わらず本規約の一部を構成するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本規約の規定とその他の利用条件等の規定に齟齬が生じる場合は、当該その他の利用条件等が優先して適用されるものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第7条（本規約の変更）")]),
      _c("p", [
        _vm._v(
          "1. 本規約は、運営者の判断により、いつでも任意に変更することができるものとします。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 変更後の利用規約は、運営者が別途定める場合を除き、本サイト上に表示した時点より効力を生じるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. 本規約の変更の効力が生じた後に、ユーザーが本サービスを利用した場合には、変更後の利用規約の全てにつき、同意したものとみなします。 "
        ),
      ]),
      _c("h2", [_vm._v("第8条（ユーザーの個人情報の取扱い）")]),
      _c("p", [
        _vm._v(" 運営者は、ユーザーの個人情報を「"),
        _c("a", { attrs: { href: "/privacy", taeget: "blank" } }, [
          _vm._v("プライバシーポリシー"),
        ]),
        _vm._v("」に基づき、適切に取り扱うものとします。 "),
      ]),
      _c("p", [
        _vm._v("プライバシーポリシーの内容をご確認の上、ご利用ください。"),
      ]),
      _c("h2", [_vm._v("第9条（知的財産権の帰属及び使用許諾）")]),
      _c("p", [
        _vm._v(
          " 本サービスに付随する情報、サービス、及びプログラム等に関する知的財産権及びその他の権利は運営者に帰属しております。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 本サービスを利用してデータ保存された保存データの著作権その他一切の権利は、当該保存データを創作したユーザーに帰属します。 "
        ),
      ]),
      _c("h2", [_vm._v("第10条（本サイトの登録）")]),
      _c("p", [
        _vm._v(
          " 1. 本サービスの利用を希望する者は、本規約の内容に同意した上で、運営者の定める所定の方法により、本サイトに登録を行うものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v("2. 運営者の提供する方法で登録した時点でユーザーになります。"),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーが次のいずれかに該当する場合は、運営者の任意の判断により、理由を開示することなくアカウント情報を削除することができます。 "
        ),
      ]),
      _c("p", [
        _vm._v("　(1) ユーザーが、第10条第1項の方法によらずに登録を行った場合"),
      ]),
      _c("p", [
        _vm._v(
          " 　(2) ユーザーが、過去に本規約又はその他の利用規約等に違反したことを理由として強制退会処分を受けた者である場合 "
        ),
      ]),
      _c("p", [_vm._v("　(3) その他、運営者が不適切と判断した場合")]),
      _c("h2", [_vm._v("第11条（アカウント情報の保有）")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーは1人につき1つのアカウントを保有するものとします。但し、作品や名義による使い分けなど、本サービスでの活動に必要性がある場合においては、その他の利用規約に反しない範囲において複数アカウントの保有を認めるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. ユーザーはいかなる場合においても、アカウント情報を第三者に譲渡又は貸与することはできません。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーがアカウント情報を紛失、削除した場合、本サイトに登録された全データを利用することができません。また、これにより生じた損害に対し運営者は一切責任を負いません。 "
        ),
      ]),
      _c("h2", [
        _vm._v(
          "第12条（登録SNSアカウント、登録メールアドレス及びパスワードの管理等）"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. ユーザーは、自分の管理に属する使用可能なメールアドレス、または、利用者本人の所有するSNSアカウントで本サイトに登録し利用するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 登録に利用した登録SNSアカウントまたは登録メールアドレスが自己の管理に属さなくなった場合には、自己の管理で保存データをバックアップし、自己の管理に属する登録SNSアカウントまたは登録メールアドレスで本サイトに登録を行い利用するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーは、自己の管理に属する登録SNSアカウント、登録メールアドレス及びパスワードの不正利用の防止に努めるとともに、その管理について一切の責任を負うものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 自己の管理に属する登録SNSアカウント、登録メールアドレス及びパスワードが第三者に利用されたことによって生じた損害等につきましては、運営者はいかなる責任も負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 5. ユーザーが第三者に登録ＳＮＳアカウントまたは登録メールアドレス及びパスワードを知られた場合、または知られた可能性がある場合は、ユーザーは自らの責任においてパスワードの変更等の対応を行うものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第13条（本サイトの保存データに関する権利）")]),
      _c("p", [
        _vm._v(
          " 本サービスを利用して、保存したデータを世間に公表をするユーザーは、運営者に対し当該保存データが第三者の権利を侵害するものでないことを保証するものとします。万が一、ユーザーが保存した保存データが第三者の権利を侵害するなど第三者との間で何らかの紛争が発生した場合には、当該ユーザーの費用と責任において問題を解決するとともに、運営者に何等の損害を与えないものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第14条（保存データの管理）")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーは、自己が作成した画像、テキスト等の保存データの全てを自己の責任において管理・保存するものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本サービスにアップロードされた画像、テキスト等の保存データは、ユーザー自身が適宜バックアップを取るなどの手法で管理するものとし、運営者は保存データのデータ保存等について一切保証しないものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第15条（ユーザーの利用環境の整備）")]),
      _c("p", [
        _vm._v(
          " 1. 本サービスを利用するために必要なあらゆるハード・ソフトウェア等の整備は、ユーザーの負担と責任において行うものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. ユーザーは自己の利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等の対策を講じるものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "3. 運営者はユーザーの利用環境について一切関与せず、また一切の責任を負いません。"
        ),
      ]),
      _c("h2", [_vm._v("第16条（ユーザーの責任）")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーは、自己の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について全ての責任を負います。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 本サービスを利用してユーザーがデータ保存した保存データに関する責任は、全てユーザーに帰属します。運営者はユーザーが本サービスを利用してデータ保存した保存データの内容について、一切責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. ユーザーが他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合、その他第三者の権利を侵害した場合には、当該ユーザーは自身の責任と費用においてこれを解決しなければならず、運営者は一切の責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. ユーザーが不正または不当な手段や方法によって運営者に損害を与えた場合、ユーザーは運営者に対しその損害を賠償するものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第17条（禁止行為）")]),
      _c("p", [
        _vm._v(
          " ユーザーは、本サービスの利用にあたり、以下の各号に該当する事項を行ってはならないものとします。禁止行為に違反した場合には、強制退会、利用停止、アカウント情報の削除、データ保存した保存データの全部もしくは一部の削除を行う場合があります（但し、運営者はこれらの措置をとる義務を負うものではなく、また、これらの措置を講じた理由を開示する義務を負うものではありません。）。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. 運営者もしくは第三者の著作権、意匠権等の知的財産権、その他の権利を侵害する行為、又は侵害する恐れのある行為。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "2. 本サイトにアップロードされている保存データを、当該著作者（創作者）の同意なくして転載する行為。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "3. 運営者もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、又は侵害する恐れのある行為。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 運営者もしくは第三者を不当に差別もしくは誹謗中傷し、第三者への不当な差別を助長、又はその名誉もしくは信用を毀損する行為。 "
        ),
      ]),
      _c("p", [_vm._v("5. 詐称行為。")]),
      _c("p", [
        _vm._v("6. 詐欺その他の犯罪に結びつく、又は結びつく恐れのある行為。"),
      ]),
      _c("p", [
        _vm._v(
          " 7. 法律・条例・その他運営者が定める基準に反するような、わいせつ、児童ポルノ又は児童虐待に相当する保存データ（以下「これらの情報」といいます。）について、次に掲げるいずれかの行為を行うこと。 "
        ),
      ]),
      _c("p", [
        _vm._v("　(1) これらの情報を収録したアカウント情報を販売する行為。"),
      ]),
      _c("p", [
        _vm._v(
          " 　(2) これらの情報を収録したアカウント情報の登録メールアドレス及び登録SNSアカウント、パスワードの送信、表示、販売を想起させる広告をデータ保存又は表示する行為。 "
        ),
      ]),
      _c("p", [_vm._v("8. 次に掲げる内容の保存データをデータ保存する行為。")]),
      _c("p", [_vm._v("　(1) 他のサービスの作品を誹謗中傷する内容の情報。")]),
      _c("p", [
        _vm._v(
          " 　(2) 氏名、住所、勤務先、電話番号等、個人を特定しうる内容の情報（データ保存された保存データに関する情報と照らし合わせることで個人を特定しうる場合を含む。）。 "
        ),
      ]),
      _c("p", [_vm._v("　(3) その他運営者が不適切と判断する内容の情報。")]),
      _c("p", [_vm._v("9. 運営者に成りすます行為")]),
      _c("p", [
        _vm._v(
          "10. 複数のアカウントを取得する行為。但し、第11条において特に認めた場合を除く。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11. マルチレベルマーケティング(MLM)・リードメール等、他人を勧誘する内容の保存データ、アダルトサイト・ワンクリック詐欺サイト・ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等、運営者が不適切と判断するサイトに誘導する保存データ（単にリンクを張る行為を含む。）、その他運営者が不適切と判断する保存データをデータ保存する行為。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 12. 通常の範囲を超えて本サービスのサーバーに負担をかける行為、もしくは、本サービスの運営やネットワーク・システムに支障を与える行為、又はこれらの恐れのある行為。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "13. 運営者の設備に蓄積された情報を不正に書き換える、又は消去する行為。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "14. ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 15. 法令、公序良俗又は本規約もしくはその他の利用条件等に違反し、他者の権利を侵害すると運営者が判断する行為。 "
        ),
      ]),
      _c("p", [_vm._v("16. その他、運営者が不適切と判断する行為。")]),
      _c("h2", [_vm._v("第18条（削除権限）")]),
      _c("p", [
        _vm._v(
          " 運営者は、次に掲げる場合には、保存データの違法性・規約違反の有無に関わらず、関連する保存データについて、その全部もしくは一部の削除等の措置を行うことができるものとします。但し、運営者はこれらの措置をとる義務を負うものではなく、また、これらの措置を講じた理由を開示する義務を負うものではありません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "1. 本サイトの運営に悪影響または損害を生ずると運営者が判断した保存データがデータ保存された場合。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 公的な機関又は専門家（国、地方公共団体、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律のガイドラインに規定された信頼性確認団体、インターネット・ホットライン、弁護士等を言います。）から、保存データについて、違法、公序良俗違反又は他人の権利を侵害する等の指摘・意見表明があった場合。 "
        ),
      ]),
      _c("p", [
        _vm._v("3. 保存データについて、第三者から正当な権利主張があった場合。"),
      ]),
      _c("p", [
        _vm._v(
          "4. データ保存された保存データが第三者の著作権を侵害すると運営者が判断した場合。"
        ),
      ]),
      _c("p", [_vm._v("5. その他、運営者が不適切と判断した場合。")]),
      _c("h2", [_vm._v("第19条（本サービスの変更、終了等）")]),
      _c("p", [
        _vm._v(
          " 1. 運営者は、事前の予告なくして、本サービスをいつでも任意の理由で追加、変更、中断、終了することができます。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 運営者は、本サービスの追加、変更、中断、終了に関し、一切の責任をおいませんので、ユーザーは自身の責任でバックアップを管理するものとします。 "
        ),
      ]),
      _c("h2", [_vm._v("第20条（免責事項）")]),
      _c("p", [
        _vm._v(
          " 1. 運営者は、本サイト内外を問わずユーザー間の紛争があった場合でも、当該ユーザー間で解決するものとし、運営者はその責任を一切負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 運営者は、運営者が必要と判断した場合は、ユーザーに通知することなくいつでも本サービスを変更、停止、又は中止することができます。この場合、運営者は、本サービスの変更等により生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や提供機能に支障等が生じた場合も同様とします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3. 運営者は、ユーザーによってデータ保存される保存データを管理・保存する義務を負いません。ユーザーは、必要に応じて自身の保存データのバックアップを取るものとします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4. 運営者は、ユーザーによってデータ保存される保存データについて、適法性、正確性等に関し一切の責任を負いません。ユーザーによってデータ保存される保存データが、当該ユーザーが所属する法人・団体等の内部規則等に関する適否についても、一切の責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 5. 運営者は、以下に掲げる場合には、当該保存データの内容を閲覧、保存、第三者に開示すること（以下、本項において「閲覧等」と言います。）ができるものとします。運営者は、それによってユーザーに生じたいかなる損害についても、一切責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 　(1) 運営者が保存データをデータ保存したユーザーの登録メールアドレス、または登録SNSアカウントに宛てて閲覧等の同意を求める電子メールまたはダイレクトメッセージを送信した場合であって、次の各号に掲げるいずれかに該当するとき。 "
        ),
      ]),
      _c("p", [_vm._v("　(2) ユーザーが運営者の閲覧等の条件に同意したとき。")]),
      _c("p", [
        _vm._v(
          "　(3) 本サービス及び関連サービスの技術的不具合の原因を解明し、解消するため必要な場合。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "　(4) 裁判所、警察等の公的機関から、法令に基づく正式な照会を受けた場合。"
        ),
      ]),
      _c("p", [
        _vm._v(
          " 　(5) 本規約、その他の利用条件等に違反する行為又はその恐れのある行為が行われ、保存データの内容を確認する必要が生じたと運営者が判断した場合。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "　(6) 人の生命、身体及び財産などに差し迫った危険があり、緊急の必要性があると運営者が判断した場合。"
        ),
      ]),
      _c("p", [
        _vm._v("　(7) その他本サイトを適切に運営するために必要が生じた場合。"),
      ]),
      _c("p", [
        _vm._v(
          " 6. 運営者は、本規約又はその他の利用条件等に違反する行為又はその恐れのある行為が行われたと信じるに足りる相当な理由があると判断した場合には、当該行為を行ったユーザーの強制退会処分、アカウント情報の全部もしくは一部の削除を行う場合がありますが、それによって生じたいかなる損害についても、一切責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 7. 運営者は、本サービスからメールなどの各種ツールを利用した際に発生した、いかなる損失において一切責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 8. ユーザーは自身の判断で保存データを本サイト外のサービスに投稿等を行うものとし、投稿先での保存データの表示等において、運営者は一切の責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 9. 運営者は、ユーザーがアカウント情報を第三者による乗っ取りなどの行為により盗まれた場合に発生した損害について一切の責任を負いません。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 10. 本サービスに事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、データの保存性、特定の目的への適合性、ご利用環境への影響、セキュリティなどに関する欠陥、エラーやバグ、その他権利侵害等を含みますが、これらに限定されません。）がないことについて明示的にも黙示的にも保証しておりません。運営者は、ユーザーに対して、これらの瑕疵を除去して本サービスを提供する義務を負いません。 "
        ),
      ]),
      _c("h2", [_vm._v("第21条（本規約及びその他の利用規約等の有効性）")]),
      _c("p", [
        _vm._v(
          " 1. 本規約及びその他の利用条件等の規定の一部が法令に基づいて無効と判断されても、本規約及びその他の利用条件等のその他の規定は有効とします。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. 利用規約等の規定の一部があるユーザーとの関係で無効とされ、又は取り消された場合でも、利用規約等はその他のユーザーとの関係では有効とします。 "
        ),
      ]),
      _c("h2", [_vm._v("第22条（違反行為等への処置）")]),
      _c("p", [
        _vm._v(
          " 1. ユーザーが本規約又はその他の利用条件等に違反したと認められる場合、その他運営者が必要と認める場合は、運営者は当該ユーザーに対し以下の処置を講ずることがあります。但し、運営者はこれらの措置をとる義務を負うものではなく、また、これらの措置を講じた理由を開示する義務を負うものではありません。 "
        ),
      ]),
      _c("p", [_vm._v("　(1) 保存データの削除・訂正を求めること。")]),
      _c("p", [_vm._v("　(2) 強制退会処分とすること。")]),
      _c("p", [
        _vm._v(
          "2. ユーザーは、前項の運営者の処置について異議を述べないこととします。"
        ),
      ]),
      _c("h2", [_vm._v("第23条（準拠法及び管轄裁判所）")]),
      _c("p", [_vm._v("1. 本規約の準拠法は、日本法とします。")]),
      _c("p", [
        _vm._v(
          "2. ユーザーと運営者の間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
        ),
      ]),
      _c("h2", [_vm._v("附則")]),
      _c("p", [_vm._v("・ 本規約は、2018年8月26日から施行します。")]),
      _c("p", [
        _vm._v(
          "・ 本規約の施行前にユーザーによって行われた行為についても本規約が適用されます。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }